// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-info-post-js": () => import("./../src/templates/info-post.js" /* webpackChunkName: "component---src-templates-info-post-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-flexyform-done-js": () => import("./../src/pages/contact-flexyform_done.js" /* webpackChunkName: "component---src-pages-contact-flexyform-done-js" */),
  "component---src-pages-contact-flexyform-js": () => import("./../src/pages/contact-flexyform.js" /* webpackChunkName: "component---src-pages-contact-flexyform-js" */),
  "component---src-pages-contact-js": () => import("./../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-information-js": () => import("./../src/pages/information.js" /* webpackChunkName: "component---src-pages-information-js" */),
  "component---src-pages-jigyo-js": () => import("./../src/pages/jigyo.js" /* webpackChunkName: "component---src-pages-jigyo-js" */),
  "component---src-pages-reason-js": () => import("./../src/pages/reason.js" /* webpackChunkName: "component---src-pages-reason-js" */)
}

